import React from "react";
import { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/ultragreen-logo-big.png";

import PromoterSection from "../Components/Sections/PromoterSection.js";

import styles from "assets/jss/material-kit-react/views/cosCertPage.js";

const useStyles = makeStyles(styles);

export default function PhenoxyethanolPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
        useEffect(() => {
          document.title = "How Much Laundry Detergent Should You Use";
        }, []);
        
  return (
    <div>
      <Header
        color="transparent"
        brand="UltraGreen Cosmetics"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg-cosmetic.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <div className={classes.typo}>
                      <h2 className={classes.title}>Phenoxyethanol</h2>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
                <p>
                    While existing scientific research on Phenoxyethanol is controversy, 
                    some experts believe it to be a toxic ingredient.
                </p>
                <p>
                    Repeated exposure to the substance over prolonged periods has been 
                    linked to contact dermatitis<a href="https://pubmed.ncbi.nlm.nih.gov/16823706/" target="_blank" rel="noopener noreferrer">[1]</a>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/16191031/" target="_blank" rel="noopener noreferrer">[2]</a>, 
                    worsening of <a href="https://pubmed.ncbi.nlm.nih.gov/18709291/" target="_blank" rel="noopener noreferrer">eczema</a>, {" "}
                    <a href="https://pubmed.ncbi.nlm.nih.gov/6351353/" target="_blank" rel="noopener noreferrer">reproductive toxicity</a> and neurotoxicity
                    <a href="https://pubmed.ncbi.nlm.nih.gov/10207615/" target="_blank" rel="noopener noreferrer">[3]</a>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/10959804/" target="_blank" rel="noopener noreferrer">[2]</a>.{" "}
                </p>
            </div>
          </div>
        </div>
        <div className={classes.container}>
                <h4 className={classes.notes}>Notes:</h4>
                <p>
                    [1] The most frequent allergens in allergic contact dermatitis, Dtsch Med Wochenschr. 2006 Jul 14;131(28-29):1584-9. doi: 10.1055/s-2006-947800.
                </p>
                <p>
                    [2] Contact allergy from phenoxyethanol in Fitostimoline gauzes, Contact Dermatitis. 2005 Oct;53(4):241. doi: 10.1111/j.0105-1873.2005.0670i.x.
                </p>
                <p>
                    [3] Effects of 2-phenoxyethanol on N-methyl-D-aspartate (NMDA) receptor-mediated ion currents, Arch Toxicol. 1999 Feb;73(1):55-9. doi: 10.1007/s002040050586.
                </p>
                <p>
                    [4] 2-Phenoxyethanol: a neurotoxicant?, Arch Toxicol. 2000 Jul;74(4-5):281-7. doi: 10.1007/s002040000110.
                </p>
        </div>
        <PromoterSection />
      </div>
      <Footer />
    </div>
  );
}
